import './main.css';
import React from 'react'
import * as serviceWorker from './serviceWorker';
import {getQueryVariable} from "./js/utils";
import * as State from "./js/state"
import * as ElmApp from "./js/elm-app"


let onboardingToken = getQueryVariable('onboardingToken')

const stripeCode = getQueryVariable('code')
if (stripeCode) {
  State.setStripeCode(stripeCode)
} else {
  if (onboardingToken) {
    State.setOnboardingToken(onboardingToken)
  } else {
    const stateString = State.getStateString()
    if (!stateString) {
      throw 'Onboarding token was not provided.'
    }
  }
}

ElmApp.mount()

serviceWorker.unregister();
