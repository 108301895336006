import * as State from "./state";
import {Elm} from "../Main.elm";
import {elmAppId} from "./config";
import {plaidRenderAndOpen} from "./plaid-link";
import {mountFinicityConnect} from "./finicity-connect";

export const mount = () => {

  const flags = State.getStateString()

  const app = Elm.Main.init({
    node: document.getElementById(elmAppId),
    flags: flags
  });

  app.ports.plaidLinkInit.subscribe(linkToken => {
    plaidRenderAndOpen(
      linkToken,
      app.ports.plaidLinkTokenReceiver.send
    )
  });

  app.ports.finicityConnectInit.subscribe(url => {
    mountFinicityConnect(
      url,
      app.ports.finicityConnectSuccess.send,
      app.ports.finicityConnectExited.send,
      app.ports.finicityConnectLoaded.send,
    )
  });
}
